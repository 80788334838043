<template>
  <section :id="'timeline-section-' + pagePosition" class="podcast" ref="section" :data-index="pagePosition">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-5 offset-md-1 order-1">
          <Observer name="fade-up">
            <h2 class="title" v-if="item.title" v-html="item.title"></h2>
            <p class="text" v-if="item.text" v-html="item.text"></p>
            <router-link v-if="item.ctaText && item.ctaLink" :to="localizedUrl + item.ctaLink" class="link-light">{{ item.ctaText }}</router-link>
            <div class="podcast-list">
              <strong class="podcast-list-title">{{ $t('Listen to the podcasts') }}</strong>
              <ul>
                <li v-for="(episode, i) in list" :key="i">
                  <audio-player :file="episode">
                    <div class="podcast-infos">
                      <div class="podcast-visual">
                        <img v-if="episode.image_url" :src="episode.image_url" alt="">
                      </div>
                      <div class="podcast-text">
                        <span class="podcast-title" v-if="episode.title">{{ episode.title }}</span>
                        <span class="podcast-artist" v-if="episode.featuring">{{ episode.featuring }}</span>
                      </div>
                    </div>
                  </audio-player>
                </li>
              </ul>
            </div>
          </Observer>
        </div>
        <div class="col-12 col-md-6 order-0">
          <Observer name="fade-up">
            <div class="visual">
              <lazy-picture :item="item" desktop-ratio="1.44827586" mobile-ratio="1.58823529"></lazy-picture>
              <button type="button" class="btn-play" @click="playVideo = true" v-if="item.video && item.video.url">
                <span>{{ $t('Play') }}</span>
              </button>
            </div>
          </Observer>
        </div>
      </div>
    </div>
    <VideoPlayerFS :play-video="playVideo" :item="item" @closeVideo="() => { playVideo = false }" v-if="item.video && item.video.url"></VideoPlayerFS>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from './utils/LazyPicture'
import VideoPlayerFS from './VideoPlayerFS'
import AudioPlayer from '../components/AudioPlayer'
import Podcast from '../utilities/podcast-mixin'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook, Podcast],
  name: 'PodcastExpertise',
  components: {
    LazyPicture,
    VideoPlayerFS,
    AudioPlayer
  },
   created () {
    this.getData(null)
  },
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  margin: 4rem 0;
}

.title {
  font-weight: 400;
  font-size: em(38);
  line-height: em(48, 38);
  margin-bottom: 2.4rem;
  color: var(--zenith-theme-primary);

  @include bp(max md) {
    font-size: em(26);
    line-height: em(30, 26);
  }
}

.text {
  font-size: em(16);
  letter-spacing: 0;
  line-height: em(28, 16);
  color: var(--zenith-grey);
  margin-bottom: 2.4rem;
}

.visual {
  position: relative;

  @include bp(max md) {
    margin-bottom: 2.4rem;
  }

  .btn-play {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ img {
    max-width: 100%;
  }
}

.podcast {

  &-list-title {
    display: block;
    font-weight: 600;
    font-size: em(12);
    line-height: em(18, 12);
    letter-spacing: em(2, 12);
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }

  &-list {
    padding: 4rem 0 0;

    ul {
      margin: 0 -2rem;
      padding: 0 2rem;
      list-style: none;
      max-height: 40rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border-radius: 2px;
        background-color: #DFDFDF;
      }

      &::-webkit-scrollbar-thumb {
        background: #262626;
        border-radius: 2px;
      }

      @include bp(max md) {
        max-height: none;
        overflow: initial !important;
        margin: 0 -1rem;
        padding: 0 1rem;
      }

      li {
        padding: 1rem 0;
        @include bp(max md) {
          padding: .6rem 0;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #DFDFDF;
        }
      }
    }
  }

  &-infos {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &-visual {
    width: 8rem;
    min-width: 8rem;
    margin-right: 2rem;
    align-self: center;

    @include bp(max md) {
      width: 6rem;
      min-width: 6rem;
    }
  }

  &-title {
    display: block;
    font-weight: 500;
    font-size: em(14);

    @include bp(max md) {
      font-size: em(12);
    }
  }

  &-artist {
    display: block;
    color: var(--zenith-grey);
    font-weight: 400;
    font-size: em(14);

    @include bp(max md) {
      font-size: em(12);
    }
  }

}

</style>
